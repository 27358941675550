<template>
  <v-app>
    <nav-bar></nav-bar>
    <slot name="banner"></slot>
    <v-main>
      <v-app-bar dense flat>
        <v-toolbar-items>
          <template v-for="(item,index) in items">
            <v-btn
              v-if="!item.items"
              :key="index"
              depressed
              text
              link
              :href="item.to"
              small
            >
              <v-icon
                left
                color="primary"
              >
                {{ item.icon }}
              </v-icon>
              {{ item.text }}
            </v-btn>

            <v-menu offset-y v-if="item.items" :key="index">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  text
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    left
                    color="primary"
                  >
                    {{ item.icon }}
                  </v-icon>
                  {{ item.text }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(subItem,index) in item.items" :key="index"
                  :to="subItem.to"
                >
                  <v-list-item-title>{{ subItem.text }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-toolbar-items>
      </v-app-bar>
      <v-row v-if="title">
        <v-col cols="12" class="pl-10 mt-4 mb-0 pb-0">
          <h2> {{ title }} </h2>
        </v-col>
      </v-row>
      <v-container fluid class="pa-0">
        <slot name="content"></slot>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import NavBar from './components/NavBar'

export default {
  components: { NavBar },
  data () {
    return {
      items: [
        {
          to: '/ferias',
          icon: 'mdi-home',
          text: 'Ferias'
        },
        {
          to: '/ferias/' + this.fairId + '/charlas',
          icon: 'mdi-calendar-month-outline',
          text: 'Charlas Técnicas'
        },
        {
          icon: 'mdi-cellphone',
          text: 'APP Movil',
          items: [
            {
              text: 'Configuración General',
              to: '/ferias/' + this.fairId + '/configuracion-general'
            },
            {
              text: 'Gestor Usuarios',
              to: '/ferias/' + this.fairId + '/admin-expositores'
            },
            {
              text: 'Visitantes',
              to: '/ferias/' + this.fairId + '/admin-visitantes'
            },
            {
              text: 'Programación General',
              to: '/ferias/' + this.fairId + '/programacion-general'
            },
            {
              text: 'Evento Académico',
              to: '/ferias/' + this.fairId + '/evento-academico'
            },
            {
              text: 'Restaurantes',
              to: '/ferias/' + this.fairId + '/restaurantes'
            },
            {
              text: 'Sitios de Interes',
              to: '/ferias/' + this.fairId + '/sitios-interes'
            },
            {
              text: 'Información General',
              to: '/ferias/' + this.fairId + '/informacion-general'
            },
            {
              text: 'Botones Planos',
              to: '/ferias/' + this.fairId + '/configuracion-lugares'
            }
          ]
        }
      ]
    }
  },
  name: 'FairLayout',
  props: {
    fairId: {
      default: null,
      type: Number
    },
    title: {
      default: null,
      type: String
    }
  }
}
</script>
